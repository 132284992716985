import { useEffect, useState } from "react";

const MOBILE_BREAKPOINT = 992;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const listener = () => setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);

    window.addEventListener("resize", listener);

    if (!window) {
      return undefined;
    }

    listener();

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return isMobile;
};
