import { graphql, PageProps } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";
import { FC } from "react";

import HomePageComponent from "../components/home/home";

export type DataProps = {
  files: {
    edges: Array<{
      node: {
        name: string;
        relativePath: string;
        relativeDirectory: string;
        childImageSharp?: ImageDataLike;
      };
    }>;
  };
  projects: {
    edges: Array<{
      node: {
        area: string;
        id: string;
        name: string;
        location: string;
        price: string;
        description: string;
        title: string;
      };
    }>;
  };
};

const HomePage: FC<PageProps<DataProps>> = ({
  data: {
    files: { edges: filesList },
    projects: { edges: projectsList },
  },
}) => {
  return (
    <HomePageComponent
      files={filesList.map(({ node }) => ({ ...node }))}
      projects={projectsList.map(({ node }) => ({ ...node }))}
    />
  );
};

export default HomePage;

export const query = graphql`
  query projects {
    files: allFile {
      edges {
        node {
          name
          relativePath
          relativeDirectory
          childImageSharp {
            id
            gatsbyImageData
          }
        }
      }
    }
    projects: allIndexJson {
      edges {
        node {
          id
          area
          title
          name
          location
          price
          description
        }
      }
    }
  }
`;
