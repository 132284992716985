import {
  FC,
  Key,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ModalContext } from "../modal/moda-context";

import "./carousel.css";

type Props = {
  items: ReactNode[];
  keys: Key[];
  width: number | string;
  height: number | string;
};

const Carousel: FC<Props> = ({ width, height, items, keys }) => {
  const [selected, setSelected] = useState(0);

  const startedMovement = useRef(0);

  const { visible } = useContext(ModalContext);

  const onClick = (newSelected: number) => () => setSelected(newSelected);

  const carouselRef = useRef<HTMLDivElement>(null);

  const onPressEnter =
    (newSelected: number) =>
    ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
      if (key === "ENTER") {
        onClick(newSelected)();
      }
    };

  useEffect(() => {
    if (!visible) {
      setSelected(0);
    }
  }, [visible]);

  useEffect(() => {
    const ref = carouselRef.current;

    const touchStartListener = (ev: TouchEvent) => {
      startedMovement.current = ev.changedTouches[0].clientX;
    };

    const touchEndListener = (ev: TouchEvent) => {
      const offset = ev.changedTouches[0].clientX - startedMovement.current;

      const enough = offset >= 0 ? offset : offset * -1;
      if (enough < 30) {
        return;
      }

      setSelected((prev) => {
        return offset < 0
          ? Math.min(items.length - 1, prev + 1)
          : Math.max(prev - 1, 0);
      });

      startedMovement.current = 0;
    };
    const touchCancelListener = () => {
      startedMovement.current = 0;
    };

    ref?.addEventListener("touchstart", touchStartListener);
    ref?.addEventListener("touchend", touchEndListener);
    ref?.addEventListener("touchcancel", touchCancelListener);

    return () => {
      ref?.removeEventListener("touchstart", touchStartListener);
      ref?.removeEventListener("touchend", touchEndListener);
      ref?.removeEventListener("touchcancel", touchCancelListener);
    };
  }, [items.length]);

  return (
    <div
      ref={carouselRef}
      style={{
        width,
        height,
      }}
      className="carousel"
    >
      {items.length > 1 && (
        <div className="dots">
          <div className="dots-wrapper">
            {items.map((node, index) => (
              <div
                key={keys[index]}
                tabIndex={0}
                onClick={onClick(index)}
                onKeyDown={onPressEnter(index)}
                aria-label={`go to item ${index + 1}`}
                role="button"
                className={`dot ${selected === index ? "dot-selected" : ""}`}
              />
            ))}
          </div>
        </div>
      )}
      {items.length > 1 && (
        <>
          <button
            aria-label="next picture"
            type="button"
            onClick={() => setSelected((prev) => (prev + 1) % items.length)}
            className="arrow right-arrow"
            disabled={selected === items.length - 1}
          >
            <div className="right-triangle triangle" />
          </button>
          <button
            type="button"
            onClick={() => setSelected((prev) => (prev - 1) % items.length)}
            className="arrow left-arrow"
            disabled={selected === 0}
          >
            <div className="left-triangle triangle" />
          </button>
        </>
      )}
      {items.map((item, index) => (
        <div
          key={keys[index]}
          style={{
            transform: `translateX(${-selected * 100 + 100 * index}%`,
          }}
          className="carousel-item"
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export { Carousel };
