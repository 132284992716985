import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image";
import { FC, useContext } from "react";

import { Layout } from "../../templates/layout/layout";
import { useIsMobile } from "../../utils/use-is-mobile";
import { Carousel } from "../carousel/carousel";
import { ModalContext } from "../modal/moda-context";
import { Seo } from "../seo";
import "./home.css";

const width = 700;
const height = 700;

type Project = {
  id: string;
  name: string;
  location: string;
  price: string;
  description: string;
  title: string;
  area: string;
};

type Props = {
  projects: Array<Project>;
  files: Array<{
    name: string;
    relativePath: string;
    relativeDirectory: string;
    childImageSharp?: ImageDataLike;
  }>;
};

const CarouselImages: FC<{ images: ImageDataLike[]; project: Project }> = ({
  images,
  project: { name, description, location, area, price },
}) => {
  const children = images.map((image, index) => (
    <GatsbyImage
      className="project-image"
      objectFit="cover"
      image={getImage(image) as IGatsbyImageData}
      alt={`imagem número ${index + 1} do projecto ${name}`}
    />
  ));

  return (
    <div>
      <section className="detail">
        <Carousel
          keys={images.map((i, index) => index)}
          items={children}
          height={height - 100}
          width={width}
        />
        <div style={{ textAlign: "center", height: 100 }}>
          <p>{description}</p>
          <p>
            {location}, {area}, {price}
          </p>
        </div>
      </section>
    </div>
  );
};

const HomePage: FC<Props> = ({ files, projects }) => {
  const { showModal } = useContext(ModalContext);
  const generateShowImageModal =
    (project: Project, images: ImageDataLike[]) => () =>
      showModal(
        <CarouselImages project={project} images={images} />,
        width,
        height
      );
  const onProjectPress =
    (project: Project, images: ImageDataLike[]) =>
    ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
      if (key === "ENTER") {
        generateShowImageModal(project, images)();
      }
    };

  const isMobile = useIsMobile();

  return (
    <Layout>
      <Seo title="Home" />
      <div className="projects">
        {projects.map((project) => {
          const mainImages = files.filter(
            (file) =>
              file.relativeDirectory === `projects/${project.name}` &&
              file.name === "main"
          );
          if (mainImages.length === 0 || mainImages.length > 1) {
            return null;
          }
          if (!mainImages[0].childImageSharp) {
            return null;
          }
          const mainImage = getImage(mainImages[0].childImageSharp);

          if (!mainImage) {
            return null;
          }

          const photos = files
            .filter(
              (file) =>
                file.relativeDirectory === `projects/${project.name}/photos` &&
                !!file.childImageSharp
            )
            .map(({ childImageSharp }) => childImageSharp);

          return (
            <section key={project.id} className="project">
              {isMobile && <h3>{project.title}</h3>}
              {!isMobile && (
                <GatsbyImage
                  imgClassName="project-in-image"
                  className="project-image"
                  image={mainImage}
                  alt={project.name}
                />
              )}
              {isMobile && (
                <Carousel
                  keys={[0].concat(photos.map((i, index) => index + 1))}
                  width="100%"
                  height={400}
                  items={[mainImages[0].childImageSharp]
                    .concat(photos as ImageDataLike[])
                    .map((image, index) => (
                      <GatsbyImage
                        className="project-image"
                        objectFit="cover"
                        image={
                          getImage(image as ImageDataLike) as IGatsbyImageData
                        }
                        alt={`imagem número ${index + 1} do projecto ${
                          project.title
                        }`}
                      />
                    ))}
                />
              )}
              {isMobile && (
                <div style={{ textAlign: "center", height: 100 }}>
                  <p>{project.description}</p>
                  <p>
                    {project.location}, {project.area}, {project.price}
                  </p>
                </div>
              )}
              {!isMobile && (
                <div className="project-image-overlay">
                  <div
                    tabIndex={0}
                    onKeyDown={onProjectPress(
                      project,
                      photos as ImageDataLike[]
                    )}
                    role="button"
                    onClick={generateShowImageModal(
                      project,
                      photos as ImageDataLike[]
                    )}
                  >
                    <h3>{project.title}</h3>
                  </div>
                </div>
              )}
            </section>
          );
        })}
      </div>
    </Layout>
  );
};

export default HomePage;
